import { createSlice } from '@reduxjs/toolkit';

// const city_id = sessionStorage.getItem('city_id');


const initialState = {
  baseUrl: 'https://joboy.in',
 // countryId: 1,
  apiKey: 'G80C80K8',  
 // serviceCityId: city_id,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
  //   setBaseUrl: (state, action) => {
  //     state.baseUrl = action.payload;
  //   },
  //   setCountryId: (state, action) => {
  //     state.countryId = action.payload;
  //   },
     
  },
});

//export const { setBaseUrl, setCountryId } = settingsSlice.actions;

export default settingsSlice.reducer;
