 import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  countries: [
    { id: 1, alphacode: 'in', url: 'http://beta.joboy.in', country: 'india' },
    { id: 2, alphacode: 'ae', url: 'http://beta.joboy.ae', country: 'uae' },
    { id: 3, alphacode: 'ca', url: 'http://beta.joboy.ca', country: 'canada' },
  ],
};

const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {},
});

export const selectApiUrl = (state) => {
  //const pathParts = window.location.pathname.split('/');
  //const alphacode = pathParts[2];
  const alphacode = 'in';
  const country = state.country.countries.find(c => c.alphacode === alphacode);
  return country ? country.url : 'http://joboy.in';
};

export default countrySlice.reducer;

