import React, { useState, useEffect } from "react";
import axios from "axios";

import { useSelector } from "react-redux";


const Home = () => {

  const { apiKey, baseUrl } = useSelector((state) => state.settings);

  const [countries, setCountries] = useState([]);

  const [alphaOptions, setAlphaOptions] = useState([]);


  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch(
          `${baseUrl}/api/country/list`,
           {
          method: "GET",
          headers: {
            Authorization: "Basic YWRtaW46MTIzNA==",
            session: apiKey,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result = await response.json();
        if (result.status === "success") {
          setCountries(result.data);

 

          
        } else {
          throw new Error("Failed to fetch countries");
        }
      } catch (error) {
        console.log(error);
      } finally {
        console.log(false);
      }
    };

    fetchCountries();
  }, [apiKey,baseUrl]);

  //const [selectedCategory, setSelectedCategory] = useState("");
  //const [selectedCity, setSelectedCity] = useState("");
  const [cityOptions, setCityOptions] = useState([]);


  const [urlOptions, setUrlOptions] = useState([]);



  //const [citylist, setCitylist] = useState([]);

  const handleCategoryChange = (event) => {

    const selectedIndex = event.target.selectedIndex;

    const selectedElement = event.target.options[selectedIndex];

    const category = event.target.value;
    const urlParam = selectedElement.getAttribute('data-url-param');
    const alphaParam = selectedElement.getAttribute('data-alpha-param');
    const fetchCitylist = async () => {
      try {
        const response = await axios.post(
          `${urlParam}/api/city/list`,
          new URLSearchParams({
            country: category,
          }),
          {
            headers: {
              Authorization: "Basic YWRtaW46MTIzNA==",
              session: apiKey,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        if (response.data.status === "success") {
          setCityOptions(response.data.data);
          setUrlOptions(urlParam);
          setAlphaOptions(alphaParam);

        } else {
          throw new Error("Failed to fetch countries");
        }
      } catch (error) {
        console.log(error);
      } finally {
        console.log(false);
      }
    };

    fetchCitylist();
  };

  const [services, setServices] = useState([]);

  const handleCityChange = async (event) => {
    const cityId = event.target.value;

    try {
      const response = await axios.post(
        `${urlOptions}/api/service/getcategoryservice`,
        new URLSearchParams({
          city: cityId,
          lang: "1",
        }),
        {
          headers: {
            Authorization: "Basic YWRtaW46MTIzNA==",
            session: apiKey,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      setServices(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div>
      <div className="container">
        <div className="wrapper">
          <div className="row">
            <div className="col-sm-10 offset-sm-1">
              <form id="frm1">
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group select">
                      <select
                        className="form-ctrl-p form-control"
                        id="sel_country"
                         
                        onChange={handleCategoryChange}
                      >
                        <option value="0" disabled="">
                          SELECT YOUR COUNTRY
                        </option>

                        {countries.map((country) => (
                          <option
                            key={country.country_id}
                            value={country.country_id}
                            data-url-param={country.url}
                            data-alpha-param={country.country_alpha2_code}
                          >
                            {country.name}
                          </option>
                        ))}
                        
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <div className="form-group select">
                      <select
                        className="form-ctrl-p form-control"
                        id="sel_city"
                        onChange={handleCityChange}
                      >
                        <option value="0">SELECT YOUR CITY</option>
                        {cityOptions.map((city) => (
                          <option key="{city.city_id}" value={city.city_id}>
                            {city.city_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="inner-demo">
            <ul className="joboy-city">
              {services.map((service) => (
                <li>
                  <a href={`${alphaOptions.toLowerCase()}/${service.seo_url}`}>
                    <img
                      src={service.category_icon}
                      className="img-fluid"
                      alt="Best Home Services in Dubai"
                    />
                    <div>{service.service_name}</div>
                  </a> 
                </li>
              ))}
            </ul>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
