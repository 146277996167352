import { configureStore } from "@reduxjs/toolkit";
import apiSlice from "../features/apiSlice";
import countryReducer from '../features/countrySlice'; 
import settingsReducer from '../features/settingsSlice'; 


export const store = configureStore({
    reducer : {
        country: countryReducer,
        api: apiSlice,
        settings: settingsReducer,
    }
})