import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
//import Modal from './Modal';


import './header.css'

// import { useDispatch, useSelector } from "react-redux";
// import { createKey, setKeyFromLocalStorage } from "./features/apiSlice";


import { useSelector } from "react-redux";



const Header = () => {

  const { apiKey, baseUrl } = useSelector((state) => state.settings);

  // const dispatch = useDispatch();
  // const { status, data } = useSelector((state) => state.api);

  // useEffect(() => {
  //    const savedKey = localStorage.getItem("apiKey");
  //   if (savedKey) {
  //     dispatch(setKeyFromLocalStorage(savedKey));
  //   } else {
  //     dispatch(createKey());
  //   }
  // }, [dispatch]);

  // useEffect(() => {
  //   if (status === "succeeded" && data && data.data) {
  //     localStorage.setItem("apiKey", data.data);
  //   }
  // }, [status, data]);
  

  // const [isModalOpen, setIsModalOpen] = useState(false);

  // const openModal = () => setIsModalOpen(true);
  // const closeModal = () => setIsModalOpen(false);

  const [sideNavWidth, setSideNavWidth] = useState(0);

  const openNav = () => {
    setSideNavWidth('100%');
  };

  const closeNav = () => {
    setSideNavWidth('0%');
  };


  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const isScrolledDown = prevScrollPos < currentScrollPos;

      setVisible(!isScrolledDown);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);



  const [countries, setCountries] = useState([]);
  //const [loading, setLoading] = useState(true);
  //const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCountries = async () => {
      try {

        const response = await fetch(
          `${baseUrl}/api/country/list`,
        {
          method: 'GET',
          headers: {
            'Authorization': 'Basic YWRtaW46MTIzNA==',
            'session': apiKey,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        if (result.status === 'success') {
          setCountries(result.data);

        } else {
          throw new Error('Failed to fetch countries');
        }
      } catch (error) {
      //  setError(error);
      } finally {
       // setLoading(false);
      }
    };

    fetchCountries();
  }, [apiKey,baseUrl]);

  return (
    <div>
      <div id="mySidenav" className="sidenav1" style={{ width: sideNavWidth }} > <span className="closebtn" onClick={closeNav}> <i className="fal fa-times close-icon"></i> </span>
        <div className="main-menu">
          <ul className="nav">
            <li className="dropdown"><Link to="/" className="dropdown-toggle" onClick={closeNav}> Home</Link></li>
            <li className="dropdown"> <span  className="dropdown-toggle" data-toggle="dropdown" > About <i className="fa fa-chevron-circle-down drop-arrow"></i> </span>
              <div className="dropdown-menu"> <Link to="/about" className="dropdown-item" href="https://joboy.com/about" onClick={closeNav}>Our Mission</Link> <Link to="/team" className="dropdown-item" href="https://joboy.com/team" onClick={closeNav}>Our Team</Link> </div>
            </li>
            <li className="dropdown"> <Link to="/services" className="dropdown-toggle"> Our services </Link> </li>
            <li className="dropdown"> <Link to="/careers" className="dropdown-toggle"> Careers </Link></li>
            <li className="dropdown"> <Link to="/contact" className="dropdown-toggle">Contact Us</Link></li>
            <li className="dropdown"> <span  className="dropdown-toggle contact-btn" data-toggle="dropdown"> Select Country <i className="fa fa-chevron-circle-down drop-arrow"></i> </span>
              <div className="dropdown-menu">
              {countries.map(country => (
                <a href={country.url} className="dropdown-item"  key={country.id || country.name}>{country.name}</a> 

                ))}
                  {/* <a className="dropdown-item" href="https://joboy.in">India</a> 
                  <a className="dropdown-item" href="https://joboy.ae">United Arab Emirates</a>
                  <a className="dropdown-item" href="https://joboy.ca">Canada</a>
                  <a className="dropdown-item" href="https://joboy.uk">United Kingdom</a> 
                  <a className="dropdown-item" href="https://joboy.az">Azerbaijan</a> 
                  <a className="dropdown-item" href="https://joboy.co">South Africa</a>  */}

                  </div>
            </li>
          </ul>
        </div>
      </div>
      <header id="header-home2" style={{ top: visible ? '0' : '-90px' }}>
        <div className="container">
          <div className="nav" id="nav">
            <div className="logo">
              <div className="toggle" onClick={openNav}> <span className="toggle-line first"></span> <span className="toggle-line second"></span> <span className="toggle-line third"></span> </div>
              <Link to="/" className="digieized"> <img src="../joboyimages/logo.png" className="img-fluid" alt="Joboy Home Services" /> </Link>  </div>

            <div className="navigation">
              <ul className="navigation-ul">
                <li className="navigation-li"> <Link to="/"  href="https://joboy.com" className="links">Home</Link> </li>
                <li className="navigation-li"> <Link to="/about" href="https://joboy.com/about" className="links">
                  About Us
                </Link>
                  <ul className="drop-ul">
                    <li className="drop-li"> <Link to="/about" href="https://joboy.com/about" className="drop-links">Our Mission</Link> </li>
                    <li className="drop-li"> <Link to="/team" href="https://joboy.com/team" className="drop-links">Our Team</Link> </li>
                  </ul>
                </li>
                <li className="navigation-li"> <Link to="/services" className="links">Our Services</Link> </li>

                {/* <li className="navigation-li"> <span className="links" onClick={openModal}>Book Services</span> </li> */}


                <li className="navigation-li"> <Link to="/careers" className="links">Careers</Link> </li>
                <li className="navigation-li"> <Link to="/contact"  className="links">contact us</Link> </li>
                <li className="navigation-li"> <span className="contact-btn">
                Select Country
                </span>
                  <ul className="drop-ul">

                  {countries.map(country => (
                          <li className="drop-li" key={country.id || country.name}> <a href={country.url} className="drop-links">
                            {country.name}
                            </a> </li>
                        ))}

                     

                    {/* <li className="drop-li"> <a href="https://joboy.in" className="drop-links">India</a> </li>
                    <li className="drop-li"> <a href="https://joboy.ae" className="drop-links">United Arab Emirates</a> </li>
                    <li className="drop-li"> <a href="https://joboy.ca" className="drop-links">Canada</a> </li>
                    <li className="drop-li"> <a href="https://joboy.uk" className="drop-links">United kingdom</a> </li>
                    <li className="drop-li"> <a href="https://joboy.az" className="drop-links">Azerbaijan</a> </li>
                    <li className="drop-li"> <a href="https://joboy.co" className="drop-links">South Africa</a> </li> */}

                  </ul>
                </li>
              </ul>

            </div>
          </div>
        </div>
      </header>

      {/* <Modal isOpen={isModalOpen} onClose={closeModal}>
        <p className='country_title'>Select Your Country</p>
        <div className="inner-demo">
          <ul className="joboy-country">
          {countries.map((country, index) => (
            <li key={`${country.id}-${index}`} id={country.id}>
               <a href={country.url}> 
              <a href='/in/kochi/services'>
                <img src={country.country_logo} className="img-fluid" alt={`Best Home Services in ${country.name}`} />
                <div>{country.name}</div>
              </a>
            </li>
          ))}
          </ul>
           </div>
      </Modal> */}

    </div>
  );
}

export default Header;

