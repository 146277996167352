// import logo from './logo.svg';
import React, {useEffect,useState} from "react";

import './App.css';

import Header from './Header';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import ScrollToTop from './ScrollToTop';

import Home from './Home';
import About from './About';
import Team from './Team';
import Services from './Services';
import ElectricianNearMe from './services/ElectricianNearMe';
import CarpenterNearMe from './services/CarpenterNearMe';
import PlumberNearMe from './services/PlumberNearMe';
import HvacServiceNearMe from './services/HvacServiceNearMe';
import Careers from './Careers';
import Contact from './Contact';
import Footer from './Footer';
import Contactus from './Contactus';
import ServicesList from './ServicesList';

import ServicesDetails from './ServicesDetails';

// import ServiceTest from './ServiceTest';



// import Header1 from "./components/Header";
// import Services1 from "./services_list/Services";
// import Footer1 from "./components/Footer";


function App() {

  const [routePath, setRoutePath] = useState("/:country/:service");

  useEffect(() => {
    // Extract the current path
    const path = window.location.pathname;

    // Update the route path based on the current URL
    setRoutePath(path);
  }, []);

  // const pathParts = window.location.pathname.split('/');
 
  // const isSpecialRoute = pathParts[3] === "services"; // Adjust the condition as needed

  return (
    <BrowserRouter>
      <ScrollToTop />
       {/* <Header1 /> */}
      <Header /> 
            {/* {isSpecialRoute ? <Header1 /> : <Header />} */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/team" element={<Team />} />
        <Route path="/services" element={<Services />} />
        <Route path="/services/electricianNearMe" element={<ElectricianNearMe />} />
        <Route path="/services/carpenterNearMe" element={<CarpenterNearMe />} />
        <Route path="/services/plumberNearMe" element={<PlumberNearMe />} /> 
        <Route path="/services/hvacServiceNearMe" element={<HvacServiceNearMe />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/contactus" element={<Contactus />} />


        <Route path="/serviceslist" element={<ServicesList />} />

        {/* <Route path="/servicetest" element={<ServiceTest />} /> */}


        <Route path={routePath} element={<ServicesDetails />} />


        
        {/* <Route path={routePath} element={<Services1 />} />  */}

      </Routes>
       <Footer />
    {/* <Footer1 />  */}
           {/* {isSpecialRoute ? <Footer1 /> : <Footer />} */}

    </BrowserRouter>
  );
}

export default App;
