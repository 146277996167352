import React, { useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel';

import FooterCity from './FooterCity';

// import { useDispatch, useSelector } from "react-redux";
// import { createKey, setKeyFromLocalStorage } from "./features/apiSlice";
import { useSelector } from "react-redux";

const Home = () => {

  const { apiKey, baseUrl } = useSelector((state) => state.settings);


  const [countries, setCountries] = useState([]);
  //const [loading, setLoading] = useState(true);
  //const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCountries = async () => {
      try {

        const response = await fetch(
          `${baseUrl}/api/country/list`,
        {
          method: 'GET',
          headers: {
            'Authorization': 'Basic YWRtaW46MTIzNA==',
            'session': apiKey,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        if (result.status === 'success') {
          setCountries(result.data);

        } else {
          throw new Error('Failed to fetch countries');
        }
      } catch (error) {
      //  setError(error);
      } finally {
       // setLoading(false);
      }
    };

    fetchCountries();
  }, [apiKey,baseUrl]);

  // const dispatch = useDispatch();
  // const { status, data } = useSelector((state) => state.api);

   

  // useEffect(() => {
  //    const savedKey = localStorage.getItem("apiKey");
  //   if (savedKey) {
  //     dispatch(setKeyFromLocalStorage(savedKey));
  //   } else {
  //     dispatch(createKey());
  //   }
  // }, [dispatch]);

  // useEffect(() => {
  //   if (status === "succeeded" && data && data.data) {
  //     localStorage.setItem("apiKey", data.data);
  //   }
  // }, [status, data]);


 
  // const [countries, setCountries] = useState([]);
  // //const [loading, setLoading] = useState(true);
  // //const [error, setError] = useState(null);

  // useEffect(() => {
  //   const fetchCountries = async () => {
  //     try {

  //     //   const apiKey =
  //     //   data && data.data ? data.data : localStorage.getItem("apiKey");

  //     // if (!apiKey) {
  //     //   throw new Error("API key is missing");
  //     // }


  //       const response = await fetch('https://testjoboyapi.work.gd/country/list', {
  //         method: 'GET',
  //         headers: {
  //           'Authorization': 'Basic YWRtaW46MTIzNA==',
  //           'session': '9FJ87Z4N',
  //           'Content-Type': 'application/x-www-form-urlencoded',
  //         },
  //       });

  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }

  //       const result = await response.json();
  //       if (result.status === 'success') {
  //         setCountries(result.data);

         
  //       } else {
  //         throw new Error('Failed to fetch countries');
  //       }
  //     } catch (error) {
  //     //  setError(error);
  //     } finally {
  //      // setLoading(false);
  //     }
  //   };

  //   fetchCountries();
  // }, []);


  // console.log('Countries state:', countries);  // Log state
   // console.log('Loading state:', loading);      // Log loading state
   // console.log('Error state:', error);          // Log error state

  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error.message}</div>;  


  const sliderRef = useRef(null);

useEffect(() => {
  const slider = sliderRef.current; // Store the current value of the ref in a variable

  if (slider) {
    $(slider).slick({
      // Slick.js options here
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1500,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 520,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    });
  }

  return () => {
    if (slider) {
      $(slider).slick('unslick');
    }
  };
}, []); // Empty dependency array if you only want to run this effect on mount/unmount




  const cities = [
    {
      ID: "11",
      category: "2",
      city_name: "Baku",
      link: "https://joboy.az/home/baku"
    },
    {
      ID: "1",
      category: "3",
      city_name: "London",
      link: "https://joboy.ca/home/london"
    },
    {
      ID: "2",
      category: "3",
      city_name: "Toronto",
      link: "https://joboy.ca/home/toronto"
    },
    {
      ID: "3",
      category: "3",
      city_name: "Calgary",
      link: "https://joboy.ca/home/calgary"
    },
    {
      ID: "4",
      category: "1",
      city_name: "Delhi NCR",
      link: "https://joboy.in/home/delhi-ncr"
    },
    {
      ID: "5",
      category: "1",
      city_name: "Kochi",
      link: "https://joboy.in/home/kochi"
    },
    {
      ID: "6",
      category: "1",
      city_name: "Mumbai",
      link: "https://joboy.in/home/mumbai"
    },
    {
      ID: "7",
      category: "1",
      city_name: "Chennai",
      link: "https://joboy.in/home/chennai"
    },
    {
      ID: "8",
      category: "1",
      city_name: "Trivandrum",
      link: "https://joboy.in/home/trivandrum"
    },
    {
      ID: "9",
      category: "1",
      city_name: "Hyderabad",
      link: "https://joboy.in/home/hyderabad"
    },
    {
      ID: "11",
      category: "1",
      city_name: "Thrissur",
      link: "https://joboy.in/home/thrissur"
    },
    {
      ID: "13",
      category: "1",
      city_name: "Kozhikode",
      link: "https://joboy.in/home/kozhikode"
    },
    {
      ID: "14",
      category: "1",
      city_name: "Bengaluru",
      link: "https://joboy.in/home/bengaluru"
    },
    {
      ID: "15",
      category: "5",
      city_name: "Dubai",
      link: "https://joboy.ae/home/dubai"
    },
    {
      ID: "16",
      category: "5",
      city_name: "Sharjah",
      link: "https://joboy.ae/home/sharjah"
    },
    {
      ID: "17",
      category: "5",
      city_name: "Abu Dhabi",
      link: "https://joboy.ae/home/abu-dhabi"
    },
    {
      ID: "18",
      category: "4",
      city_name: "Greater London",
      link: "https://joboy.uk/home/greater-london"
    },
    {
      ID: "19",
      category: "7",
      city_name: "Johannesburg",
      link: "https://joboy.co/home/johannesburg"
    },
    {
      ID: "20",
      category: "7",
      city_name: "Cape Town",
      link: "https://joboy.co/home/cape-town"
    },
    {
      ID: "21",
      category: "7",
      city_name: "Garden Route",
      link: "https://joboy.co/home/garden-route"
    }
  ];

  // const categories = [   
  //   { ID: "1", name: "India" },
  //   { ID: "2", name: "United Arab Emirates" },
  //   { ID: "3", name: "Canada" },
  //   { ID: "4", name: "United Kingdom" },
  //   { ID: "5", name: "Azerbaijan" },
  //   { ID: "6", name: "United States of America" },
  //   { ID: "7", name: "South Africa" }
  // ];
  

  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [cityOptions, setCityOptions] = useState([]);
  const [link, setLink] = useState('');

  const handleCategoryChange = (event) => {
    const category = event.target.value;
    setSelectedCategory(category);
    const filteredCities = cities.filter(city => city.category === category);
    setCityOptions(filteredCities);
    setSelectedCity('');
    setLink('');
  };

  const handleCityChange = (event) => {
    const cityId = event.target.value;
    const selectedCity = cityOptions.find(city => city.ID === cityId);
    setSelectedCity(cityId);
    setLink(selectedCity ? selectedCity.link : '');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (link) {
      window.location.href = link;
    }
  };


  return (
    <div>
      <div className="container">
        <div className="wrapper">
          <div className="heading">
            <div className="slider-tag-outer"> </div>
            <div className="slider-heading-outer">
              <h1 className="slider-heading wow fadeInUp h1" data-wow-duration="1s" data-wow-delay=".5s"> Looking for Expert Home  <br />
                Services at Your Convenience? </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10 offset-sm-1">
              <form onSubmit={handleSubmit} ole="form" id="frm1">
                <div className="row">

                  <div className="col-sm-12 col-md-6">
                    <div className="form-group select">


                      <select className="form-ctrl-p form-control" id="sel_country" value={selectedCategory} onChange={handleCategoryChange}>
                        <option value="0" disabled="">SELECT YOUR COUNTRY</option>
                        {/* {categories.map(category => ( */}
                        {countries.map(country => (
                          <option key={country.country_id} value={country.country_id}>
                            {country.name}
                          </option>
                        ))}
                        {/* <option value="1">India</option>
                        <option value="5">United Arab Emirates</option>
                        <option value="3">Canada</option>
                        <option value="4">United kingdom</option>
                        <option value="2">Azerbaijan</option>
                        <option value="7">South Africa</option> */}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <div className="form-group select">


                      <select className="form-ctrl-p form-control" id="sel_city" value={selectedCity} onChange={handleCityChange} disabled={!selectedCategory}>
                        <option value="0">SELECT YOUR CITY</option>
                        {cityOptions.map(city => (
                          <option key={city.ID} value={city.ID}>
                            {city.city_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    {/* <button className="btn book-now-btn btn-block" type="submit" onclick="goToCity();">Book Now</button> */}
                    <button className="btn book-now-btn btn-block" type="submit" disabled={!selectedCity}>
                      Book Now
                    </button>
                  </div>


                </div></form>
            </div>
          </div>
          <div className="shape">
            <svg className="svg">
              <clipPath id="my-clip-path" clipPathUnits="objectBoundingBox">
                <path d="M0.004,0.683 c0.012,0.08,0.048,0.145,0.09,0.154 c0.036,0.008,0.074,-0.022,0.108,0.006 c0.03,0.026,0.047,0.093,0.075,0.129 c0.026,0.033,0.06,0.035,0.089,0.017 s0.055,-0.055,0.079,-0.094 c0.015,-0.024,0.029,-0.051,0.046,-0.068 s0.038,-0.025,0.055,-0.01 c0.03,0.025,0.041,0.102,0.068,0.141 c0.03,0.044,0.072,0.029,0.104,-0.008 c0.025,-0.028,0.048,-0.069,0.076,-0.07 c0.027,-0.001,0.05,0.036,0.076,0.05 c0.051,0.028,0.099,-0.044,0.118,-0.138 c0.023,-0.118,0.005,-0.251,-0.002,-0.372 c-0.006,-0.097,-0.006,-0.2,-0.033,-0.281 c-0.025,-0.075,-0.069,-0.116,-0.113,-0.123 S0.753,0.032,0.712,0.065 C0.673,0.095,0.632,0.136,0.591,0.119 C0.556,0.106,0.529,0.054,0.496,0.025 A0.143,0.27,0,0,0,0.394,0.012 C0.35,0.038,0.31,0.105,0.263,0.099 c-0.042,-0.006,-0.078,-0.073,-0.12,-0.084 S0.058,0.057,0.042,0.137 C0.029,0.206,0.036,0.283,0.031,0.356 C0.024,0.455,-0.005,0.549,0.001,0.649 a0.112,0.211,0,0,0,0.004,0.034"></path>
              </clipPath>
            </svg>
            <div className="clipped">
              <div className="download-strip mob-none">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-6">
                      <h5 className="text-right download-text"> <strong>DOWNLOAD THE JOBOY APP</strong></h5>
                    </div>
                    <div className="col-sm-6 form-inline">
                      <div className="form-group p-1"> <a href="https://apps.apple.com/us/app/joboy-home-services-repairs/id1270174460" className="btn btn-market btn--with-shadow"> <img className="utouch-icon" src="./joboyimages/ios.png" alt="Joboy appstore" />
                        <div className="text"> <span className="sup-title">Download on the</span> <span className="title">App Store</span> </div>
                      </a> </div>
                      <div className="form-group p-1"> <a href="https://play.google.com/store/apps/details?id=com.serville.joboy" className="btn btn-market btn--with-shadow"> <img className="utouch-icon" src="./joboyimages/google-play.svg" alt="Joboy Google play store" />
                        <div className="text"> <span className="sup-title">Download on the</span> <span className="title">Google Play</span> </div>
                      </a> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="download-strip mob mt-3 mb-3">
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <h5 className="text-right download-text">Download the Joboy App</h5>
              </div>
              <div className="col-sm-6 form-inline">
                <div className="form-group p-1"> <a href="https://apps.apple.com/us/app/joboy-home-services-repairs/id1270174460" className="btn btn-market btn--with-shadow"> <img className="utouch-icon" src="./joboyimages/ios.png" alt="appstore" />
                  <div className="text"> <span className="sup-title">Download on the</span> <span className="title">App Store</span> </div>
                </a> </div>
                <div className="form-group p-1"> <a href="https://play.google.com/store/apps/details?id=com.serville.joboy" className="btn btn-market btn--with-shadow"> <img className="utouch-icon" src="./joboyimages/google-play.svg" alt="google" />
                  <div className="text"> <span className="sup-title">Download on the</span> <span className="title">Google Play</span> </div>
                </a> </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="popular-services">
        <div className="container">

          <div className="customer-logos slider" ref={sliderRef}>
            <div className="slide">
              <div className="features bg0 text-center mt-25 pb-20"> 
                <div className="icon mb-20"> <img src="./joboyimages/Services.png" alt="Joboy Services" /> </div>
                <div className="content">
                  <h4 className="features-title">Services</h4>
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="features bg1 text-center mt-25 pb-20"> 
                <div className="icon mb-20"> <img src="./joboyimages/store.png" alt="Joboy Shopping" /> </div>
                <div className="content">
                  <h4 className="features-title">Shopping</h4>
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="features bg2 text-center mt-25 pb-20"> 
                <div className="icon mb-20"> <img src="./joboyimages/deals.png" alt="Joboy Deals" /> </div>
                <div className="content">
                  <h4 className="features-title">Deals</h4>
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="features bg3 text-center mt-25 pb-20"> 
                <div className="icon mb-20"> <img src="./joboyimages/ticket.png" alt="Joboy Events" /> </div>
                <div className="content">
                  <h4 className="features-title">Events</h4>
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="features bg4 text-center mt-25 pb-20"> 
                <div className="icon mb-20"> <img src="./joboyimages/ticket.png" alt="More Joboy Services" /> </div>
                <div className="content">
                  <h4 className="features-title">More</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pb40 pt40">
        <div className="container">
          <h2 className="h2block pb25">How do <span>we help you?</span></h2>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <div className="row pb15">
                <div className="col-sm-6"> <img src="./joboyimages/No-more-searching.png" alt="Easy to find" />
                  <h4 className="pt15 pb15">No more searching</h4>
                  <p>We take away the pain of searching for a service expert. No more asking your neighbors, No phone calls, No internet search. Just the Joboy App.</p>
                </div>
                <div className="col-sm-6"> <img src="./joboyimages/No-more-follow-ups.png" alt="No waiting" />
                  <h4 className="pt15 pb15">No more follow ups</h4>
                  <p>Once you book a service, we do everything else for you. No more calling the vendor, no waiting, no follow ups, no unnecessary cancellations. Just relax.</p>
                </div>
                <div className="clearfix"></div>
              </div>
              <div className="row pb15">
                <div className="col-sm-6"> <img src="./joboyimages/No-more-bargaining.png" alt="Fixed pricing" />
                  <h4 className="pt15 pb15">No more bargaining</h4>
                  <p>Most of our prices are pre-fixed, and are available for you to check at the time of booking. If not listed, you will get a quote in advance for you to approve.</p>
                </div>
                <div className="col-sm-6"> <img src="./joboyimages/No-more-paper.png" alt="Save the earth" />
                  <h4 className="pt15 pb15">No more paper</h4>
                  <p>You will get to see the live status of your request, will receive an electronic invoice, and will also be able to pay online using secure transactions.</p>
                </div>
                <div className="clearfix"></div>
              </div>
              <div className="row pb15">
                <div className="col-sm-6"> <img src="./joboyimages/No-more-complaints.png" alt="Service warranty" />
                  <h4 className="pt15 pb15">No more complaints</h4>
                  <p>In a rare situation where something doesn't work the way it was supposed to, our service warranty covers the work done, so you can still be free of worries.</p>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6"> <img src="./joboyimages/How-do-we-help-you.jpg" className="img-fluid" alt="How Joboy helps you" /> </div>
          </div>
        </div>
      </div>
      <div className="gray-bg pt40 pb40">
        <div className="container">
          <h3 className="pb40">From basic home repairs to technology services, we have it all.</h3>
        </div>
        <div className="owl-carousel owl-2">
          <div className="media-29101"> <a href="https://joboy.com/services"><img src="./joboyimages/home_maintenance.jpg" alt="home mainteance services" className="img-fluid" /></a>
            <div className="service-cotent">
              <h3>Home Maintenance</h3>
              <h5>Find Experts Near You</h5>
            </div>
          </div>
          <div className="media-29101"> <a href="https://joboy.com/services"><img src="./joboyimages/appliance_repairs.jpg" alt="appliance repair services" className="img-fluid" /></a>
            <div className="service-cotent">
              <h3>Appliance Repairs</h3>
              <h5>With Service Warranty</h5>
            </div>
          </div>
          <div className="media-29101"> <a href="https://joboy.com/services"><img src="./joboyimages/cleaning_services.jpg" alt="home cleaning services" className="img-fluid" /></a>
            <div className="service-cotent">
              <h3>Cleaning Services</h3>
              <h5>Excellent Heigene</h5>
            </div>
          </div>
          <div className="media-29101"> <a href="https://joboy.com/services"><img src="./joboyimages/home_renovation.jpg" alt="home renovation services" className="img-fluid" /></a>
            <div className="service-cotent">
              <h3>Home Renovation</h3>
              <h5>Premium Experience</h5>
            </div>
          </div>
          <div className="media-29101"> <a href="https://joboy.com/services"><img src="./joboyimages/health_beauty_fitness.jpg" alt="health beauty and fitness" className="img-fluid" /></a>
            <div className="service-cotent">
              <h3>Health & Fitness</h3>
              <h5>Experienced Professionals</h5>
            </div>
          </div>
          <div className="media-29101"> <a href="https://joboy.com/services"><img src="./joboyimages/technology_services.jpg" alt="technology services" className="img-fluid" /></a>
            <div className="service-cotent">
              <h3>Technology Services</h3>
              <h5>Best In The Industry</h5>
            </div>
          </div>
        </div>
      </div>
      <div className="pattern pt40 pb40">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-7">
              <h2 className="h2block pb15">You are at the <span>center of our world</span></h2>
              <p className="pb40">Joboy brings you the best of experts in Home and local services, Home Maintenance, Appliance repairs, Utility services, Lifestyle services, Beauty and health services, Repairs and maintenance, and other professional local service providers, right where you are, at your convenient time. We have built our services and technology from a customer's view, so our services are centred around the customers' needs.</p>
              <h2 className="h2block pb15">Why JOBOY?</h2>
              <p className="pb40">In a world where you are running around wearing too many hats at the same time, wishing you had someone to help, someone you could confidently rely on, here comes Joboy. We save you time, effort, money, and give you peace of mind, while solving your expected and unexpected chores, even with a warranty on our services.</p>
              <div className="row">
                <div className="col-sm-6">
                  <ul className="td-list mb-20">
                    <li><i><img src="joboyimages/tck-mark.png" alt="trusted brand" /></i> Globally Trusted Brand</li>
                    <li><i><img src="joboyimages/tck-mark.png" alt="background verified" /></i> Verified Service Partners</li>
                    <li><i><img src="joboyimages/tck-mark.png" alt="timely services" /></i> On-Time Service</li>
                    <li><i><img src="joboyimages/tck-mark.png" alt="pre-fixed prices" /></i> Transparent Pricing</li>
                  </ul>
                </div>
                <div className="col-sm-6">
                  <ul className="td-list mb-20">
                    <li><i><img src="joboyimages/tck-mark.png" alt="service warranty" /></i> Service Warranty</li>
                    <li><i><img src="joboyimages/tck-mark.png" alt="secure payments" /></i> Secure Payment Options</li>
                    <li><i><img src="joboyimages/tck-mark.png" alt="excellent customer service" /></i> 24/7 Customer Support</li>
                    <li><i><img src="joboyimages/tck-mark.png" alt="electronic invoices" /></i> Electronic Invoices</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-5"> <img src="./joboyimages/why-joboy.png" className="img-fluid" alt="how does joboy help?" /> </div>
          </div>
        </div>
      </div>
      <div className="pb40 pt40">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div id="newsticker">
                <h2 className="pb15">In the News</h2>
                <div className="newsticker-jcarousellite">
                  <ul>
                    <li>
                      <div className="row">
                        <div className="col-sm-4"> <a href="https://www.business-standard.com/content/press-releases-ani/joboy-to-begin-service-operations-in-canada-and-azerbaijan-121060201084_1.html" ><img src="./joboyimages/news-4.png" alt="" /> </a></div>
                        <div className="col-sm-8">
                          <p className="pt15 pb15">June 2, 2021</p>
                          <h4>Joboy to start operations in Canada and Azerbaijan</h4>
                        </div>
                      </div>
                      <div className="clear"></div>
                    </li>
                    <li>
                      <div className="row">
                        <div className="col-sm-4"> <a href="https://economictimes.indiatimes.com/small-biz/startups/newsbuzz/home-services-app-joboy-to-expand-to-bigger-metros-in-india/articleshow/71854905.cms" ><img src="./joboyimages/news-1.jpg" alt="" /> </a></div>
                        <div className="col-sm-8">
                          <p className="pt15 pb15">November 1, 2019</p>
                          <h4>Joboy app to expand to bigger metros</h4>
                        </div>
                      </div>
                      <div className="clear"></div>
                    </li>
                    <li>
                      <div className="row">
                        <div className="col-sm-4"> <a href="https://www.newindianexpress.com/cities/bengaluru/2019/jun/13/home-services-a-click-away-1989363.html" ><img src="./joboyimages/news-2.jpg" alt="" /></a></div>
                        <div className="col-sm-8">
                          <p className="pt15 pb15">June 9, 2019</p>
                          <h4>Home services a click Away</h4>
                        </div>
                      </div>
                      <div className="clear"></div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <h2>Testimonials</h2>
              <h5 className="pb40">What our customers have to say about our services.</h5>
              <div className="testimonial-icon"> <i className="fa fa-quote-left"></i> </div>
              <div id="testimonial-slider" className="owl-carousel">
                <div className="testimonial">
                  <div className="testimonial-content">
                    <p className="description"> I used Joboy app to avail the service of an electrician. The service personnel came to my home was a qualified experienced person. The payment was also as agreed with the customer care executive while confirming the booking. </p>
                  </div>
                  <h3 className="title">Abhilash M S</h3>
                  <span className="post">On Google Play Store</span> </div>
                <div className="testimonial">
                  <div className="testimonial-content">
                    <p className="description"> The customer care executives are very helpful. They get back to you as soon as you book for a service.. And the service providers are good too. Work is efficient and they see to it that it's done. </p>
                  </div>
                  <h3 className="title">Juline Rose</h3>
                  <span className="post">On Google Play Store</span> </div>
                <div className="testimonial">
                  <div className="testimonial-content">
                    <p className="description"> Highly recommended.. I have availed electrical services and deep cleaning services.. Their services are really good, especially when we reach our apartment after a long time. </p>
                  </div>
                  <h3 className="title">Christy Augustine</h3>
                  <span className="post">On Google Play Store</span> </div>
                <div className="testimonial">
                  <div className="testimonial-content">
                    <p className="description"> Technician came over to do a few odd jobs and did great work. Fixed a window, a sink and an electrical plug all for a reasonable price. I am satisfied with his work and your service. Would definitely use them again. </p>
                  </div>
                  <h3 className="title">Jim Mangaly</h3>
                  <span className="post">On Google Play Store</span> </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterCity />
    </div>

  );
};

export default Home;
