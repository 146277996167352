import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
//import { selectApiUrl } from './countrySlice'; 

 

// const countries = [
//   { id: 1, alphacode: "in", url: "http://beta.joboy.in", country: 'india' },
//   { id: 2, alphacode: "ae", url: "http://beta.joboy.ae", country: 'uae' },
//   { id: 3, alphacode: "ca", url: "http://beta.joboy.ca", country: 'canada' },   
// ];

// const getApiUrl = () => {

//  const pathParts = window.location.pathname.split('/');
//  const alphacode = pathParts[2]; 
//  // Find the matching country URL
//  const country = countries.find(c => c.alphacode === alphacode);
//  return country ? country.url : 'http://joboy.in';
 
// };


// Define the async thunk for the API call
export const createKey = createAsyncThunk('api/createKey', async (_, { getState }) => {
  
 // const state = getState();
  //const apiUrl = selectApiUrl(state);

  //console.log(apiUrl);


  const response = await axios.post(
     'https://testjoboyapi.work.gd/key/createkey',
    // `${apiUrl}:3000/key/createkey`,
        new URLSearchParams({
      customer_id: '',
      device_id: '234',
      firebase_token: '234214214',
      app_version: '6',
      platform: 'an',
    }),
    {
      headers: {
        'Authorization': 'Basic YWRtaW46MTIzNA==',
        'session': '',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );

  //console.log(response.data);
  return response.data;
});

const apiSlice = createSlice({
  name: 'api',
  initialState: {
    status: null,
    data: null,
    error: null,
  },
  reducers: {
    setKeyFromLocalStorage: (state, action) => {
      state.data = { key: action.payload };
      state.status = 'succeeded';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createKey.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createKey.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(createKey.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { setKeyFromLocalStorage } = apiSlice.actions;

export default apiSlice.reducer;
